<template>
    <div>
        <v-card id="bp_map">
            <v-card-title>
                <v-icon left>mdi-map</v-icon>
                    <span class="subtitle-1 font-weight-regular application--card--title">Locations</span>
                    <v-spacer></v-spacer>
                <v-btn icon @click="card1 = !card1">
                    <v-icon>{{ card1 ? 'mdi-chevron-down' : 'mdi-chevron-up' }}</v-icon>
                </v-btn>
            </v-card-title>
            <v-divider v-show="card1"></v-divider>
            <v-card-text id="map--card" v-show="card1">
                
                <!-- google maps -->
                <GmapMap
                    :center="getGmapSettings.mapCenter"
                    :zoom="getGmapSettings.zoom"
                    map-type-id="roadmap"
                    style="width: 100%; height: 1000px"
                    :options="mapOptions()"
                    ref="mapRef"
                    >
                    
                    <!--
                    <GmapCluster
                    :zoomOnClick=true
                    :styles="clusterStyles"
                    >
                        <GmapMarker
                            :key="index"
                            v-for="(m, index) in markers"
                            :position="m.position"
                            :clickable="true"
                            :draggable="false"
                            @click="toggleInfoWindow(m, index)"
                            :icon="mapMarker(m)"
                        />
                    </GmapCluster>-->
                    
                    
                    <GmapMarker
                        :key="index"
                        v-for="(m, index) in markers"
                        :position="m.position"
                        :clickable="true"
                        :draggable="false"
                        @click="toggleInfoWindow(m, index)"
                        :icon="mapMarker(m)"
                    />

                    <gmap-info-window :options="infoOptions" :position="infoWindowPos" :opened="infoWinOpen" @closeclick="infoWinOpen=false">
                    </gmap-info-window>
                </GmapMap>

            </v-card-text>
            
            <v-divider v-show="card1"></v-divider>
            <v-card-text v-show="card1">
                <v-container class="" v-if="$vuetify.breakpoint.lgAndUp">
                    <v-row align="start" >
                        <v-col v-for="(item, key) in mapIcons" :key="key">
                            <v-row align="center" style="">
                                <v-col align="center" style="" xs="6" sm="2" md="2" lg="2" xl="2">
                                    <img :src="item.src">
                                </v-col>
                                <v-col xs="6" sm="10" md="10" lg="10" xl="10">
                                    <p class="pt-3">{{ item.title }}</p>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-container>
                <v-container class="" v-else>
                    <v-row align="center" style="" no-gutters v-for="(item, key) in mapIcons" :key="key">
                        <v-col align="left" style="" xs="6" sm="2" md="2" lg="2" xl="2">
                            <img :src="item.src">
                        </v-col>
                        <v-col xs="6" sm="10" md="10" lg="10" xl="10">
                            <p class="pt-3">{{ item.title }}</p>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
import { EventBus } from '@/main.js'
import imgRepair from '@/assets/repair_2.png'
import imgToiletGreen from '@/assets/toilets_green.png'
import imgToiletOrange from '@/assets/toilets_orange.png'
import imgToiletRed from '@/assets/toilets_red.png'
import imgCaution from '@/assets/caution.png'
import {gmapApi} from 'vue2-google-maps'
import * as VueGoogleMaps from 'vue2-google-maps'
import { createDecipheriv } from 'crypto'
import { getHoursDiff, getMinutesDiff } from '@/helpers/utilities.js'

export default {
    name: 'business-premise-map',

    data : () => ({
        clusterStyles: [
        ],

        card1 : true,

        active_alarm: true,

        interval1: null,
        timeout1: null,

        infoWindowPos: null,
          infoWinOpen: false,
          currentMidx: null,

          infoOptions: {
        	content: '',
            //optional: offset infowindow so it visually sits nicely on top of our marker
            pixelOffset: {
              width: 0,
              height: -35
            }
          },

        gmapSettings: {
            center: {lat:45.818422821442134, lng:15.982895878506158},
            mapCenter: {lat:45.818422821442134, lng:15.982895878506158},
            zoom: 9,
            mapZoom: 9,
            infoContent: '',
            infoWindowPos: {
                lat: 0,
                lng: 0
            },
            infoWinOpen: false,
            currentMidx: null,
            //optional: offset infowindow so it visually sits nicely on top of our marker
            infoOptions: {
                pixelOffset: {
                        width: 0,
                        height: -35
                    }
            }
        },

        markers: [],
        markersData: [],
        businessPremises: [],
        markersAlarm : [],

        warning_pfill: null,
        warning_telemetry_last_update: null,

        selected_sales_locations: [],
        selected_cashiers: []
    }),

    watch: {
        card1: {
            handler(value) {
                //window.console.log("Telemetry card1: " + value)
                this.$store.commit("APP_SETTING", {
                    setting: "telemetry",
                    key: "map_open",
                    value: value
                })
            },

            //immediate: true
        }
    },

    computed: {
        google: VueGoogleMaps.gmapApi, 

        iconRepair() {
            return imgRepair
        },

        iconToiletGreen() {
            return imgToiletGreen
        },

        iconToiletOrange() {
            return imgToiletOrange
        },

        iconToiletRed() {
            return imgToiletRed
        },

        iconCaution() {
            return imgCaution
        },

        mapIcons() {
            const icoRepair = {
                src: imgRepair,
                title : "Error / Alarm"
            }

            const icoToiletGreen = {
                src: imgToiletGreen,
                title : "Last invoice less than 24 hours ago"
            }

            const icoToiletOrange = {
                src: imgToiletOrange,
                title : "Last invoice between 24 and 30 hours ago"
            }

            const icoToiletRed = {
                src: imgToiletRed,
                title : "Last invoice more than 30 hours ago"
            }

            const icoCaution = {
                src: imgCaution,
                title : "Warning"
            }

            const icons = [
                icoRepair, 
                icoToiletGreen,
                icoToiletOrange,
                icoToiletRed,
                icoCaution
            ];

            return icons
        },

        getGmapSettings() {
            return this.gmapSettings
        },

        getDemoParkingLots() {
            return this.parkingLots
        }
    },

    methods: {
        mapMarker(marker) {
            //window.console.log("mapMarker")
            //window.console.log(marker)

            let markerIcon = this.iconToiletGreen

            //return "https://bled.conforma.si/public/img/icons/p-avto.png"

            let alarmError = false;
            let lastInvoice24 = false;
            let lastInvoice2430 = false
            let lastInvoice30 = false
            let warning = false

            // alarm, error - telemetrija vsebuje napake (errors)
            // warning - malo papirje, neosvezena telemetrija več kot 15 min, ...


            marker.cashiers.forEach(cashier => {
                if(cashier.telemetry !== null) {
                    let telemetryErrAlarm = cashier.telemetry.errors
                    if(telemetryErrAlarm.length > 0) {
                        if(telemetryErrAlarm.length == 1) {
                            if(telemetryErrAlarm[0] === 'Voucher in ticket slot' || telemetryErrAlarm[0] === 'Voucher in ticket slot;') {
                                warning = true;
                            } else {
                                alarmError = true;
                            }
                        } else {
                            alarmError = true;
                        }
                    }

                    let pfill = cashier.telemetry.pfill
                    if(pfill !== "" && pfill !== null ) {
                        pfill = parseInt(pfill)
                        //window.console.log(pfill)
                        if(pfill < 300) {
                            warning = true
                        }
                    }

                    let telemetryUpdateDateTime = cashier.telemetry.last_update;
                    
                    //window.console.log(telemetryUpdateDateTime)
                    
                    if(telemetryUpdateDateTime !== "" && telemetryUpdateDateTime !== null) {
                        let currentDate = new Date()

                        let day = (currentDate.getDate()).toString().padStart(2, "0");
                        let month = (currentDate.getMonth() +1).toString().padStart(2, "0")
                        let year = currentDate.getFullYear();

                        let currentDateTime = year + "-" + month + "-" + day + " " + currentDate.getHours().toString().padStart(2, "0") + ":" + currentDate.getMinutes().toString().padStart(2, "0") + ":" + currentDate.getSeconds().toString().padStart(2, "0");

                        let dih = getMinutesDiff(currentDateTime, telemetryUpdateDateTime)
                        if(dih > 15) {
                            warning = true
                        }
                    }


                }

                if(cashier.last_invoice !== null) {
                    let invoiceDateTimeStr = cashier.last_invoice.date_time
                    if(invoiceDateTimeStr !== null && invoiceDateTimeStr !== "") {
                        let currentDate = new Date()

                        let day = (currentDate.getDate()).toString().padStart(2, "0");
                        let month = (currentDate.getMonth() +1).toString().padStart(2, "0")
                        let year = currentDate.getFullYear();

                        let currentDateTime = year + "-" + month + "-" + day + " " + currentDate.getHours().toString().padStart(2, "0") + ":" + currentDate.getMinutes().toString().padStart(2, "0") + ":" + currentDate.getSeconds().toString().padStart(2, "0");

                        //window.console.log(currentDateTime)

                        let dih = getHoursDiff(currentDateTime, invoiceDateTimeStr)

                        //window.console.log("currentDateTime: " + currentDateTime)
                        //window.console.log(dih)

                        if(dih < 24) {
                            lastInvoice24 = true
                            //window.console.log("a");
                        }

                        else if(dih >= 24 && dih <= 30) {
                            lastInvoice2430 = true
                            //window.console.log("b");
                        }
                        else {
                            lastInvoice30 = true
                            //window.console.log("c");
                        }

                    }
                }
            })

            if(lastInvoice24) {
                markerIcon = this.iconToiletGreen
            }
            
            if(lastInvoice2430) {
                markerIcon = this.iconToiletOrange
            }
            if(lastInvoice30) {
                markerIcon = this.iconToiletRed
            }
            
            if(warning == true) {
                markerIcon = this.iconCaution
            }

            if(alarmError) {
                markerIcon = this.iconRepair
            }
            
            

            return markerIcon
        },

        mapOptions() {
            return {
                minZoom: process.env.VUE_APP_MAP_MIN_ZOOM || 4,
                maxZoom: process.env.VUE_APP_MAP_MAX_ZOOM || 20,
                /*styles: [
                    {
                        "stylers": [
                            {
                                "saturation": -100
                            },
                            {
                                "gamma": 1
                            }
                        ]
                    },
                    {
                        "elementType": "labels.text.stroke",
                        "stylers": [
                            {
                                "visibility": "off"
                            }
                        ]
                    },
                    {
                        "featureType": "poi.business",
                        "elementType": "labels.text",
                        "stylers": [
                            {
                                "visibility": "off"
                            }
                        ]
                    },
                    {
                        "featureType": "poi.business",
                        "elementType": "labels.icon",
                        "stylers": [
                            {
                                "visibility": "off"
                            }
                        ]
                    },
                    {
                        "featureType": "poi.place_of_worship",
                        "elementType": "labels.text",
                        "stylers": [
                            {
                                "visibility": "off"
                            }
                        ]
                    },
                    {
                        "featureType": "poi.place_of_worship",
                        "elementType": "labels.icon",
                        "stylers": [
                            {
                                "visibility": "off"
                            }
                        ]
                    },
                    {
                        "featureType": "road",
                        "elementType": "geometry",
                        "stylers": [
                            {
                                "visibility": "simplified"
                            }
                        ]
                    },
                    {
                        "featureType": "water",
                        "stylers": [
                            {
                                "visibility": "on"
                            },
                            {
                                "saturation": 50
                            },
                            {
                                "gamma": 0
                            },
                            {
                                "hue": "#50a5d1"
                            }
                        ]
                    },
                    {
                        "featureType": "administrative.neighborhood",
                        "elementType": "labels.text.fill",
                        "stylers": [
                            {
                                "color": "#333333"
                            }
                        ]
                    },
                    {
                        "featureType": "road.local",
                        "elementType": "labels.text",
                        "stylers": [
                            {
                                "weight": 0.5
                            },
                            {
                                "color": "#333333"
                            }
                        ]
                    },
                    {
                        "featureType": "transit.station",
                        "elementType": "labels.icon",
                        "stylers": [
                            {
                                "gamma": 1
                            },
                            {
                                "saturation": 50
                            }
                        ]
                    }
                ]
                */
                /* WEBLAGAJNA */
                
                styles: [
                    {
                        "featureType": "landscape",
                        "stylers": [
                            {
                                "hue": "#FFBB00"
                            },
                            {
                                "saturation": 43.400000000000006
                            },
                            {
                                "lightness": 37.599999999999994
                            },
                            {
                                "gamma": 1
                            }
                        ]
                    },
                    {
                        "featureType": "road.highway",
                        "stylers": [
                            {
                                "hue": "#FFC200"
                            },
                            {
                                "saturation": -61.8
                            },
                            {
                                "lightness": 45.599999999999994
                            },
                            {
                                "gamma": 1
                            }
                        ]
                    },
                    {
                        "featureType": "road.arterial",
                        "stylers": [
                            {
                                "hue": "#FF0300"
                            },
                            {
                                "saturation": -100
                            },
                            {
                                "lightness": 51.19999999999999
                            },
                            {
                                "gamma": 1
                            }
                        ]
                    },
                    {
                        "featureType": "road.local",
                        "stylers": [
                            {
                                "hue": "#FF0300"
                            },
                            {
                                "saturation": -100
                            },
                            {
                                "lightness": 52
                            },
                            {
                                "gamma": 1
                            }
                        ]
                    },
                    {
                        "featureType": "water",
                        "stylers": [
                            {
                                "hue": "#0078FF"
                            },
                            {
                                "saturation": -13.200000000000003
                            },
                            {
                                "lightness": 2.4000000000000057
                            },
                            {
                                "gamma": 1
                            }
                        ]
                    },
                    {
                        "featureType": "poi",
                        "stylers": [
                            {
                                "hue": "#00FF6A"
                            },
                            {
                                "saturation": -1.0989010989011234
                            },
                            {
                                "lightness": 11.200000000000017
                            },
                            {
                                "gamma": 1
                            }
                        ]
                    }
                ]
                
            }
        },

        infoWindowCashiersContent(marker) {
            let cashiers = marker.cashiers

            let contentStr = "";
            let warningLastUpdate = false
            let lastInvoiceDiffColor = null;

            cashiers.forEach(cashier => {
                contentStr += "<span style='font-weight:800;font-size:1.1em;'>Cashier number: </span><span style='font-weight:600;font-size:1.1em;'><u>" + cashier.cashier_nr + "</u></span><br>";
                //contentStr += "<span style='font-weight:800;font-size:1.1em;'>Last invoice: </span><span style='font-weight:500;font-size:1.1em;'>" + ((cashier.last_invoice != null) ? cashier.last_invoice.invoice_nr + " (" + cashier.last_invoice.date_time + ")" : " / " ) + "</span><br>"

                if(cashier.last_invoice !== null) {
                    let invoiceDateTimeStr = cashier.last_invoice.date_time
                    if(invoiceDateTimeStr !== null && invoiceDateTimeStr !== "") {
                        let currentDate = new Date()

                        let day = (currentDate.getDate()).toString().padStart(2, "0");
                        let month = (currentDate.getMonth() +1).toString().padStart(2, "0")
                        let year = currentDate.getFullYear();

                        let currentDateTime = year + "-" + month + "-" + day + " " + currentDate.getHours().toString().padStart(2, "0").toString().padStart(2, "0") + ":" + currentDate.getMinutes().toString().padStart(2, "0") + ":" + currentDate.getSeconds().toString().padStart(2, "0");

                        let dih = getHoursDiff(currentDateTime, invoiceDateTimeStr)

                        if(dih < 24) {
                            lastInvoiceDiffColor = "green"
                        }

                        else if(dih >= 24 && dih <= 30) {
                            lastInvoiceDiffColor = "orange";
                        }
                        else {
                            lastInvoiceDiffColor = "red";
                        }

                    }
                }

                if(lastInvoiceDiffColor !== null) {
                    if(lastInvoiceDiffColor == 'green') {
                        contentStr += "<span style='font-weight:800;font-size:1.1em;'>Last invoice: </span><span style='font-weight:500;font-size:1.1em;color:#83D932;'>" + ((cashier.last_invoice != null) ? cashier.last_invoice.invoice_nr + " (" + cashier.last_invoice.date_time + ")" : " / " ) + "</span><br>"
                    }

                    if(lastInvoiceDiffColor == 'orange') {
                        contentStr += "<span style='font-weight:800;font-size:1.1em;'>Last invoice: </span><span style='font-weight:500;font-size:1.1em;color:#CD8900;'>" + ((cashier.last_invoice != null) ? cashier.last_invoice.invoice_nr + " (" + cashier.last_invoice.date_time + ")" : " / " ) + "</span><br>"
                    }

                    if(lastInvoiceDiffColor == 'red') {
                        contentStr += "<span style='font-weight:800;font-size:1.1em;'>Last invoice: </span><span style='font-weight:500;font-size:1.1em;color:#FF0000;'>" + ((cashier.last_invoice != null) ? cashier.last_invoice.invoice_nr + " (" + cashier.last_invoice.date_time + ")" : " / " ) + "</span><br>"
                    }

                } else {
                    contentStr += "<span style='font-weight:800;font-size:1.1em;'>Last invoice: </span><span style='font-weight:500;font-size:1.1em;'>" + ((cashier.last_invoice != null) ? cashier.last_invoice.invoice_nr + " (" + cashier.last_invoice.date_time + ")" : " / " ) + "</span><br>"
                }

                if(cashier.telemetry !== null) {
                    let telemetry = cashier.telemetry


                    //contentStr += "<span style='font-weight:800;font-size:1.1em;'>Last update: </span><span style='font-weight:500;font-size:1.1em;'>" + telemetry.last_update + "</span><br>"

                    let telemetryUpdateDateTime = cashier.telemetry.last_update;
                    if(telemetryUpdateDateTime !== "" && telemetryUpdateDateTime !== null) {
                        let currentDate = new Date()

                        let day = (currentDate.getDate()).toString().padStart(2, "0");
                        let month = (currentDate.getMonth() +1).toString().padStart(2, "0")
                        let year = currentDate.getFullYear();

                        let currentDateTime = year + "-" + month + "-" + day + " " + currentDate.getHours().toString().padStart(2, "0") + ":" + currentDate.getMinutes().toString().padStart(2, "0") + ":" + currentDate.getSeconds().toString().padStart(2, "0");

                        let dih = getMinutesDiff(currentDateTime, telemetryUpdateDateTime)
                        if(dih > 15) {
                            warningLastUpdate = true
                        }
                    } 

                    if(warningLastUpdate) {
                        contentStr += "<span style='font-weight:800;font-size:1.1em;'>Last update: </span><span style='font-weight:500;font-size:1.1em;color:#2518e1;'>" + telemetry.last_update + "</span><br>"
                    } else {
                        contentStr += "<span style='font-weight:800;font-size:1.1em;'>Last update: </span><span style='font-weight:500;font-size:1.1em;'>" + telemetry.last_update + "</span><br>"
                    }




                    contentStr += "<span style='font-weight:800;font-size:1.1em;'>Traffic: </span><span style='font-weight:500;font-size:1.1em;'>" + ( (telemetry.coins !== null) ? telemetry.coins + "€" : "N/A") + "</span><br>"
                    contentStr += "<span style='font-weight:800;font-size:1.1em;'>Coin exchanger sum: </span><span style='font-weight:500;font-size:1.1em;'>" + telemetry.chng + "€</span><br>"
                    contentStr += "<span style='font-weight:800;font-size:1.1em;'>CE last empty: </span><span style='font-weight:500;font-size:1.1em;'>" + telemetry.oldchng + "€</span><br>"

                    let pfill = telemetry.pfill
                    if(pfill !== null && pfill !== "") {
                        pfill = parseInt(pfill)

                        if(pfill < 300) {
                            contentStr += "<span style='font-weight:800;font-size:1.1em;'>Paper level: </span><span style='font-weight:500;font-size:1.1em;color:#2518e1;'>" + telemetry.pfill + "</span><br>"
                        } else {
                            contentStr += "<span style='font-weight:800;font-size:1.1em;'>Paper level: </span><span style='font-weight:500;font-size:1.1em;'>" + telemetry.pfill + "</span><br>"    
                        }

                    } else {
                        contentStr += "<span style='font-weight:800;font-size:1.1em;'>Paper level: </span><span style='font-weight:500;font-size:1.1em;'>" + telemetry.pfill + "</span><br>"
                    }

                    let errorsStr = "<span style='color:green;font-weight:500;font-size:1.1em;'>No errors found.</span>";
                    if(telemetry.errors.length > 0) {
                        errorsStr = ""
                        telemetry.errors.forEach(error => {
                            if(error == "Voucher in ticket slot" || error == "Voucher in ticket slot;") {
                                errorsStr = "<span style='color:#4d42e6;font-weight:500;font-size:1.1em;'>" + error + "; " + "</span>"
                            } else {
                                errorsStr = "<span style='color:red;font-weight:500;font-size:1.1em;'>" + error + "; " + "</span>"
                            }

                            
                        })
                    }

                    contentStr += "<span style='font-weight:800;font-size:1.1em;'>Alarm: </span>" + errorsStr 
                }

                contentStr +="<br><br>"
            })

            return contentStr
        },

        toggleInfoWindow(marker, idx) {
            //window.console.log(marker)
            this.infoWindowPos = marker.position;
            this.infoOptions.content = marker.name + "<br><br>" + marker.position.lat + " " + marker.position.lng;

            let infoWindowContent = "";
            infoWindowContent += "<span style='font-weight:800;font-size:1.2em;'>" + marker.name + "</span>"

            infoWindowContent +="<br><br><span style='font-weight:800;font-size:1.1em;'><u>Cashiers:</u></span><br><br>"
            infoWindowContent += this.infoWindowCashiersContent(marker)

            this.infoOptions.content = infoWindowContent;

            //check if its the same marker that was selected if yes toggle
            if (this.currentMidx == idx) {
              this.infoWinOpen = !this.infoWinOpen;
            }
            //if different marker set infowindow to open and reset current marker index
            else {
              this.infoWinOpen = true;
              this.currentMidx = idx;
            }

        },

        getParameters() {
            var vm = this
            vm.$store.commit('SET_PROGRESS', true)

            this.$store.dispatch("MAP_BUSINESS_PREMISES")
            .then(response => {
                //window.console.log(response.data)
                vm.businessPremises = response.data
                vm.setMarkers(response.data)

            })
            .catch(err => {
                window.console.error("### BusinessPremiseMap@getParameters ###")
                window.console.error(err)
            })
            .finally(() => {
                setTimeout(() => {
                    vm.$store.commit('SET_PROGRESS', false)  
                }, 700)
            })
        },

        setMarkers(items) {
            let vm = this
            let c = 1;
            vm.markers = new Array()
            vm.markersAlarm = new Array();

            if(vm.selected_sales_locations.length < 1 && vm.selected_cashiers.length < 1) {
                items.forEach(item => {
                    //window.console.log(item.lat, ",", item.lng)
                    //window.console.log(item.id)

                    let markerItem = {}

                    markerItem.position = {
                        lat: Number(item.lat),
                        lng: Number(item.lng)
                    }

                    markerItem.id = item.id;
                    markerItem.name = item.name
                    markerItem.group = item.group
                    markerItem.sales_location_nr = item.sales_location_nr
                    markerItem.cashiers = item.cashiers
                    markerItem.uuid = item.uuid

                    markerItem.alarm = false;

                    // preveriš če je na kakšni napravi (blagajni) alarm
                    item.cashiers.forEach(cashier => {
                        if("telemetry" in cashier) {
                            if(cashier.telemetry !== null) {
                                if(cashier.telemetry.cs !== "OK" || cashier.telemetry.sta2 !== 'OK' || cashier.telemetry.stat !== 'OK') {
                                    markerItem.alarm = true;
                                    //window.console.log(markerItem.name)
                                }
                            }
                        }
                    })

                    if(markerItem.alarm === true) {
                        vm.markersAlarm.push(markerItem)
                    }


                    vm.markers.push(markerItem)
                    vm.markersData.push(markerItem)

                    //vm.gmapSettings.mapCenter = {lat: Number(markerItem.position.lat), lng: Number(markerItem.position.lng)}
                    //vm.gmapSettings.zoom = 11

                    /*
                    const bounds = new window.google.maps.LatLngBounds()
                    for(let m of vm.markers) {
                        bounds.extend(m.position)
                    }

                    vm.$refs.mapRef.fitBounds(bounds)
                    */
                        
                })
            } else {
                items.forEach(item => {
                    if(vm.selected_sales_locations.includes(item.id)) {
                        let markerItem = {}

                        markerItem.position = {
                            lat: Number(item.lat),
                            lng: Number(item.lng)
                        }

                        markerItem.id = item.id;
                        markerItem.name = item.name
                        markerItem.group = item.group
                        markerItem.sales_location_nr = item.sales_location_nr
                        markerItem.cashiers = item.cashiers
                        markerItem.uuid = item.uuid

                        markerItem.alarm = false;

                        // preveriš če je na kakšni napravi (blagajni) alarm
                        item.cashiers.forEach(cashier => {
                            if("telemetry" in cashier) {
                                if(cashier.telemetry !== null) {
                                    if(cashier.telemetry.cs !== "OK" || cashier.telemetry.sta2 !== 'OK' || cashier.telemetry.stat !== 'OK') {
                                        markerItem.alarm = true;
                                        //window.console.log(markerItem.name)
                                    }
                                }
                            }
                        })

                        if(markerItem.alarm === true) {
                            vm.markersAlarm.push(markerItem)
                        }


                        vm.markers.push(markerItem)
                    }    
                })

                //console.log(vm.markers)
                //window.console.log(vm.selected_cashiers)
                if(vm.selected_cashiers.length > 0) {
                    vm.selected_cashiers.forEach(cashier => {
                        let cashierExists = false;
                        vm.markers.forEach(marker => {
                            marker.cashiers.forEach(mc => {
                                if(mc.uuid == cashier) {
                                    cashierExists = true
                                }
                            })
                        })

                        if(!cashierExists) {
                            vm.markersData.forEach(md => {
                                md.cashiers.forEach(mdc => {
                                    if(mdc.uuid == cashier) {
                                        vm.markers.push(md)
                                    }
                                })
                            })
                        }
                    })
                }
            }

            if(vm.active_alarm === true && vm.markersAlarm.length > 0) {
                vm.markers = vm.markersAlarm
                vm.fitBounds(vm.markersAlarm)

            } else {
                vm.fitBounds(vm.markers)
            }

            //window.console.log(this.markers)
            //window.console.log(this.markersAlarm)
        },

        fitBounds(markers) {
            let vm = this
            let isWebkit = typeof window.webkitConvertPointFromNodeToPage === 'function';

            //console.log("isWebkit: " + isWebkit)

            //let bounds = new window.google.maps.LatLngBounds() /// problem na iphone (webkit) --- undefined is not an object (evaluating new window.google.maps)
            //if(isWebkit) {
                //bounds = new this.google.maps.LatLngBounds();
                //window.console.log(new this.google.maps.LatLngBounds())

                /*
                this.$gmapApiPromiseLazy().then(() => {
                //your code here
                //E.g. for directions
                var directionsService = new this.google.maps.DirectionsService();
                });
                */
            //}

            this.$gmapApiPromiseLazy().then(() => {
                const bounds = new this.google.maps.LatLngBounds();

            
                markers.forEach(marker => {
                    //window.console.log(marker.lat, +" - ", marker.lng)
                    bounds.extend({lat: Number(marker.position.lat), lng: Number(marker.position.lng)})
                })

                vm.timeout1 = setTimeout(() => {
                    if(typeof vm.$refs.mapRef !== "undefined") {
                        vm.$refs.mapRef.fitBounds(bounds)
                    }
                }, 400)
            });
        }
    },

    created() {
        this.card1 = this.$store.getters.appSettings.telemetry.map_open
        this.warning_pfill = process.env.VUE_APP_WARNING_PFILL || 300
        this.warning_telemetry_last_update = process.env.VUE_APP_WARNING_TELEMETRY_LAST_UPDATE || 15
        this.getParameters()

    },

    mounted() {
        let vm = this
        vm.$refs.mapRef.$mapPromise.then(() => {
            setTimeout(() => {
                if(vm.active_alarm && vm.markersAlarm.length > 0) {
                    vm.fitBounds(vm.markersAlarm)
                } else {
                    vm.fitBounds(vm.markers)
                }
            }, 4000)
        })


        // let startDateTime = "2022-12-19 10:00:00";
        // startDateTime = '2022-06-15T16:30:01.000000Z'
        // let endDateTime = "2022-12-20 10:00:00";

        setTimeout(() => {
            //window.console.log(getHoursDiff(startDateTime, endDateTime))
            //window.console.log(getMinutesDiff(startDateTime, endDateTime))
        }, 1500)

        EventBus.$on('refresh_dt_telemetry', function(data) {
            //window.console.log("Test")
            //window.console.log(data)

            vm.selected_sales_locations = []
            vm.selected_cashiers = []
            vm.selected_sales_locations = data.selectedLocations
            vm.selected_cashiers = data.selectedCashiers
            vm.active_alarm = data.activeAlarm
            vm.markers = []
            /*setTimeout(() => {
                vm.setMarkers(vm.businessPremises)
            }, 200)
            */
            vm.getParameters()



        })

        window.addEventListener('resize', () => {
            vm.fitBounds(vm.markers)
        });
    },

    unmounted() {
        window.removeEventListener('resize', () => {
            window.console.log("unmount resize event")
        });
    },

    beforeDestroy() {
        EventBus.$off('refresh_dt_telemetry');

        clearInterval(this.interval1)
        clearTimeout(this.timeout1)
    },

    destroyed() {

    }
}

</script>

<style scoped>
    #map--card {
        padding: 0;
    }
</style>